import React, { Component } from 'react';
import './App.css';
import logo from './Images/logo.jpg'
import 'bootstrap/dist/css/bootstrap.min.css';
import Countdown from './counter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhoneSquareAlt, faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

class App extends Component {
  componentDidMount() {
    document.title = 'TAIYAKI';
  }

  render() {
    return (
      <div>
        <div className="App">
          <div className="containers row">
            <span id="removePaading"></span>

            <div id="Content" className="col-xl-7">
              <img src={logo} className="logo" />
              <h1> No.1 Japanese Dessert is Coming Soon ... </h1>
              <p className="col-xl-8 col-lg-6 col-md-7 col-sm-8 col-10" > Our amazing website is under construction, we are working very hard to give you the best experience with us. </p>
              <p> Be Prepared for <b>" TAIYAKI "</b> </p>
              <section id="counter">
                <Countdown timeTillDate="05 05 2021, 6:00 pm" timeFormat="MM DD YYYY, h:mm a" />
              </section>
            </div>

            <div className="col-xl-1 JapanLine">
              <span className="whiteLine">
                <span className="lineForMargin"></span>
                <span className="redLine"></span>
              </span>
            </div>

            <footer className="col-xl-4">
              <div className="allContacts">
                <div className="contact">
                  <a href="https://wa.me/+201141101109" target="_blank">
                    <i> <FontAwesomeIcon icon={faPhoneSquareAlt} /> </i>
                    <p> | </p>
                    <i> <FontAwesomeIcon icon={faWhatsapp} /> </i>
                    <p> 01141101109 </p>
                  </a>
                </div>
                <div className=" contact">
                  <a href="mailto:info@taiyakiegypt.com" target="_blank">
                    <i> <FontAwesomeIcon icon={faEnvelope} /> </i>
                    <p> info@taiyakiegypt.com </p>
                  </a>
                </div>
                <div className="contact">
                  <i> <FontAwesomeIcon icon={faMapMarkerAlt} /> </i>
                  <p className="addressText"> 2 Almaza St from El Thawra St - Helioples </p>
                </div>
              </div>
            </footer>

            <div className="clearFloat"></div>

          </div>
        </div>

      </div>
    );

  }
}


export default App;
